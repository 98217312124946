<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title>{{ folder }}</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">{{ folder }}</ion-title>
        </ion-toolbar>
      </ion-header>

      <div id="container">
        <!-- <ion-grid>
          <ion-row>
            <ion-col> ion-col </ion-col>
            <ion-col> ion-col </ion-col>
          </ion-row>
          <ion-row>
            <ion-col> ion-col </ion-col>
            <ion-col> ion-col </ion-col>
          </ion-row>
        </ion-grid> -->

        <ion-card @click="() => router.push('/notiziario')">
          <ion-card-header>
            <ion-card-subtitle>I notiziari della parrocchia</ion-card-subtitle>
            <ion-card-title>Notiziario</ion-card-title>
          </ion-card-header>

          <ion-card-content>
            <ion-img
              src="https://parrocchianoventa.org/images/NOTIZIARIO.png"
            ></ion-img>
          </ion-card-content>
        </ion-card>

        <ion-card @click="() => router.push('/catechismo')">
          <ion-card-header>
            <ion-card-subtitle>I calendari del catechismo</ion-card-subtitle>
            <ion-card-title>Catechismo</ion-card-title>
          </ion-card-header>

          <ion-card-content>
            <ion-img
              src="https://parrocchianoventa.org/images/documenti/catechismo2020/GESU_1.jpg"
            ></ion-img>
          </ion-card-content>
        </ion-card>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonImg,
} from "@ionic/vue";
import { useRoute, useRouter } from "vue-router";
import { ref, computed, watch } from "vue";

export default {
  name: "Folder",
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCardContent,
    IonImg,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const folder = ref(route.params.id || "Home");
    const matchedFolder = computed(() => route.params.id);

    watch(matchedFolder, () => {
      folder.value = matchedFolder.value as string;
    });

    return { folder, router };
  },
};
</script>

<style scoped>
ion-menu-button {
  color: var(--ion-color-primary);
}

#container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  /* top: 50%;
  transform: translateY(-50%); */
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

#container a {
  text-decoration: none;
}
</style>